import React from "react"
import * as Icon from "react-feather"

const contactItem = [
  // {
  //   heading: `USA – Princeton`,
  //   para: (
  //     <p>
  //       Atyeti Inc (Global Headquarters)
  //       <br />
  //       125 Village Blvd, Suite 320
  //       <br />
  //       Princeton NJ 08540
  //       <br />
  //     </p>
  //   ),
  // },
  {
    heading: `USA – New York`,
    para: (
      <p>
        Atyeti Inc
        <br />
        295 Madison Avenue
        <br />
        Ste 1203, 12th Floor
        <br />
        New York
        <br />
      </p>
    ),
  },
  {
    heading: `USA – Virginia`,
    para: (
      <p>
        Atyeti Inc
        <br />
        11710 Plaza America Dr.
        <br />
        Suite 120
        <br />
        Reston, VA 20190
        <br />
      </p>
    ),
  },
  {
    heading: `USA – North Carolina`,
    para: (
      <p>
        Atyeti Inc
        <br />
        7001 Weston Parkway
        <br /> Suite 170
        <br />
        Cary, NC 27513
        <br />
      </p>
    ),
  },
  {
    heading: `United Kingdom`,
    para: (
      <p>
        Atyeti Limited,
        <br />
        46 New Broad Street
        <br />
        London EC2M 1JH       
      </p>
    ),
  },
  {
    heading: `India - Trivandrum`,
    para: (
      <p>
        ATYETI IT Services Pvt Ltd
        <br />
        Module No.7 Basement Floor of
        <br />
        Park Centre building,
        <br />
        Phase-I Campus,Technopark
        <br />
        Trivandrum Kerala 695 581
      </p>
    ),
  },
  {
    heading: `India – Pune`,
    para: (
      <p>
        Atyeti IT Services Pvt Ltd
        <br />
        Level 9, Tower No 2, S. No. 1,
        <br />
        World Trade Centre,
        <br />
        Kharadi, Pune,
        <br />
        Maharashtra, 411014
      </p>
    ),
  },
  {
    heading: `India – Hyderabad`,
    para: (
      <p>
        Atyeti IT Services Pvt Ltd.
        <br />
        We Work, 6th floor, Krishe Emerald,
        <br />
        Kondapur Main Road, Laxmi Cyber City,
        <br /> Whitefield, Kondapur,
        <br />
        Hyderabad, Telangana 500081.
      </p>
    ),
  },
  {
    heading: `India - Bengaluru`,
    para: (
      <p>
        WeWork Embassy TechVillage, Block L,
        <br />
        Devarabisanahalli,
        <br />
        Outer Ring Rd, Bellandur,
        <br />
        Bengaluru, Karnataka 560103
      </p>
    ),
  },
  {
    heading: `India - Chennai`,
    para: (
      <p>
        M/s. ATYETI IT SERVICES PRIVATE LIMITED,
        <br />
        Dot cowork - Tidel Park, <br />
        Module no. 115, 1st Floor,
        <br />
        No.4 Rajiv Gandhi Salai, <br />
        Tharamani Chennai – 600113
      </p>
    ),
  },
  {
    heading: `Singapore`,
    para: (
      <p>
        Atyeti Pte Ltd
        <br />
        101 Cecil Street
        <br />
        #20-08 Tong Eng Building
        <br />
        Singapore 069533
        <br />
      </p>
    ),
  },
  {
    heading: `Poland`,
    para: (
      <p>
        Grabarska I, Pegaz Building B<br />
        3rd Floor Office 304
        <br />
        Wroclaw 50079
        <br />
        Poland
      </p>
    ),
  },
  {
    heading: `Switzerland`,
    para: (
      <p>
        Atyeti
        <br />
        Zurich City Center
        <br />
        Dreikönigstrasse 31a
        <br />
        8002 Zürich
        <br />
      </p>
    ),
  },

  {
    heading: `Canada`,
    para: (
      <p>
        Atyeti IT Consulting
        <br />
        250 University Avenue
        <br />
        Suite 200
        <br />
        Toronto, ON M5H 3E5
      </p>
    ),
  },
  {
    heading: `Hong Kong`,
    para: (
      <p>
        Atyeti Limited
        <br />
        Unit 706, 7/F, South Seas Centre
        <br />
        Tower 2, 75 Mody Road
        <br />
        Tsim Sha Tsui
        <br />
        Kowloon Hong Kong
      </p>
    ),
  },
  {
    heading: `Ireland`,
    id: 11,
    para: (
      <p>
        Atyeti Limited
        <br />
        71 Lower Baggot Street
        <br />
        Ground Floor
        <br />
        Dublin D02 P593
        <br />
        Ireland
      </p>
    ),
  },
  {
    heading: `Malaysia`,
    para: (
      <p>
        Atyeti Sdn Bhd
        <br />
        Level 35-02 (East Wing), Q Sentral
        <br />
        2A, Jalan Stesen Sentral
        <br />
        2, KL Sentral
        <br />
        Kuala Lumpur, Malaysia 50470
      </p>
    ),
  },
  {
    heading: `Colombia`,
    para: (
      <p>
        Atyeti SAS Carrera 9<br />
        No. 71 – 70 of 303
        <br />
        Bogota Colombia
      </p>
    ),
  },
]

const ContactInfo = () => {
  return (
    <div className="contact-info-area pt-80">
      <div className="container">
        <div className="row justify-content-center">
          {contactItem.map((item, idx) => (
            <div className="col-lg-4 col-md-6 col-sm-6 pb-50" key={idx}>
              <div className="contact-info-box">
                <div className="icon">
                  <Icon.MapPin />
                </div>
                <h3>{item.heading}</h3>
                {item.para}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
